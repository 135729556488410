<template>
  <div class="databind">
    <el-form label-width="120px"
             label-position="left"
             size="small">
      <el-form-item label="数据类型">
        <avue-select v-model="contain.activeObj.dataType"
                     placeholder="请选择数据类型"
                     :dic="dicOption.dataType"></avue-select>
      </el-form-item>
      <template v-if="isStatic">
        <el-form-item label="数据值"
                      label-position="top">
          <el-button size="small"
                     type="primary"
                     icon="el-icon-edit"
                     @click="contain.openCode('data','数据值')">编辑</el-button>
        </el-form-item>
      </template>
      <template v-if="isSql">
        <el-form-item label="数据源选择">
          <avue-select :dic="DIC.sql"
                       v-model="db"></avue-select>
        </el-form-item>
        <el-form-item label="SQL语句"
                      label-position="top">
          <monaco-editor v-model="sql"
                         language="sql"
                         height="100"></monaco-editor>
        </el-form-item>
      </template>
      <template v-else-if="isApi || isNode">
        <el-form-item label="开启跨域"
                      v-if="isApi">
          <avue-switch v-model="contain.activeObj.proxy"
                       @click="contain.handleRefresh"></avue-switch>
        </el-form-item>
        <el-form-item label="请求方式"
                      v-if="isApi">
          <avue-select v-model="contain.activeObj.dataMethod"
                       placeholder="请选择请求方式"
                       :dic="dicOption.dataMethod"></avue-select>
        </el-form-item>
        <el-form-item label="地址">
          <avue-input v-model="contain.activeObj.url"
                      placeholder="请输入地址"></avue-input>
        </el-form-item>
      </template>
      <template v-else-if="isWs">
        <el-form-item label="WS地址">
          <el-input v-model="contain.activeObj.wsUrl">
          </el-input>
        </el-form-item>
      </template>
      <template v-else-if="isRecord">
        <el-form-item label="分类">
          <avue-select :dic="DIC.recordType"
                       placeholder="请选择分类"
                       @change="handleRecordTypeChange"
                       :props="{label:'categoryKey',value:'categoryValue'}"
                       v-model="contain.activeObj.recordType"></avue-select>
        </el-form-item>
        <el-form-item label="数据集">
          <avue-select :dic="DIC.record"
                       filterable
                       placeholder="请选择数据集"
                       v-model="contain.activeObj.record"></avue-select>
        </el-form-item>
      </template>
      <template v-else-if="isPublic">
        <el-form-item label="公共数据集选择">
          <avue-select :dic="DIC.public"
                       filterable
                       v-model="contain.activeObj.public"></avue-select>
        </el-form-item>
      </template>
      <template v-else-if="isMqtt">
        <el-form-item label="MQTT地址">
          <el-input v-model="contain.activeObj.mqttUrl">
          </el-input>
        </el-form-item>
        <el-form-item label="MQTT配置">
          <monaco-editor v-model="contain.activeObj.mqttConfig"
                         height="250"></monaco-editor>
        </el-form-item>
      </template>
      <el-form-item label="刷新时间"
                    v-if="isTime">
        <el-input v-model="contain.activeObj.time"
                  placeholder="0">
          <span slot="append">毫秒</span>
        </el-input>
      </el-form-item>

      <el-form-item label="过滤器">
        <div class="avue-flex">
          <avue-select v-model="contain.activeObj.dataFormatterId"
                       :dic="DIC.filter"
                       :props="{label:'name',value:'id'}"></avue-select>
          <el-button size="small"
                     v-if="!contain.activeObj.dataFormatterId"
                     type="primary"
                     icon="el-icon-edit"
                     @click="contain.openCode('dataFormatter','编辑过滤器')">编辑</el-button>
        </div>
      </el-form-item>

      <el-form-item label-width="0">
        <el-button size="small"
                   type="primary"
                   class="block"
                   @click="handleSetting">更多设置</el-button>
      </el-form-item>

      <el-form-item label="返回数据"
                    label-width="100">
        <div class="avue-flex"
             style="justify-content: flex-end;">
          <el-button size="small"
                     type="primary"
                     @click="handleRes">请求数据</el-button>
        </div>
      </el-form-item>
      <el-tabs class="menu__tabs"
               style="padding:0 10px;"
               v-model="resTabs">
        <el-tab-pane label="处理后数据"
                     name="0">
          <json-viewer v-loading="dataLoading"
                       v-bind="$loadingParams"
                       :value="dataRes"
                       copyable
                       theme="avue-json-theme"
                       boxed></json-viewer>
        </el-tab-pane>
        <el-tab-pane label="原始数据"
                     name="1">
          <json-viewer v-loading="dataLoading"
                       v-bind="$loadingParams"
                       :value="dataOldRes"
                       copyable
                       theme="avue-json-theme"
                       boxed></json-viewer>
        </el-tab-pane>
      </el-tabs>
    </el-form>

    <database ref="database"></database>
  </div>
</template>

<script>
import { getList as getCategoryList } from '@avue/avue-data/api/category'
import MonacoEditor from '@avue/avue-data/page/components/monaco-editor'
import crypto from '@avue/avue-data/utils/crypto'
import database from '@avue/avue-data/page/setup/database'
import { getList as getDbList } from "@avue/avue-data/api/db";
import { getList as getRecordList } from "@avue/avue-data/api/record";
import { dicOption } from '@avue/avue-data/option/config'
export default {
  inject: ["contain"],
  provide () {
    return {
      contain: this.contain,
      dataParent: this
    };
  },
  components: {
    database,
    MonacoEditor
  },
  data () {
    return {
      resTabs: 0,
      dataLoading: false,
      dataRes: '',
      dataOldRes: '',
      DIC: {
        filter: [],
        sql: [],
        record: [],
        recordType: [],
        public: [],
        project: [],
        devices: []
      },
      db: '',
      sql: '',
      dicOption,
    }
  },
  computed: {
    isTime () {
      return !this.isStatic && !this.isPublic && !this.isWs && !this.isMqtt
    },
    isStatic () {
      return this.contain.activeObj.dataType == 0
    },
    isApi () {
      return this.contain.activeObj.dataType == 1
    },
    isSql () {
      return this.contain.activeObj.dataType == 2
    },
    isWs () {
      return this.contain.activeObj.dataType === 3
    },
    isRecord () {
      return this.contain.activeObj.dataType == 4
    },
    isPublic () {
      return this.contain.activeObj.dataType == 5
    },
    isMqtt () {
      return this.contain.activeObj.dataType == 6
    },
    isNode () {
      return this.contain.activeObj.dataType == 7
    }
  },
  created () {
    this.initDataList();
    this.handleRes(false);
  },
  methods: {
    handleRecordTypeChange (val) {
      getRecordList({
        dataModule: val.value,
        current: 1,
        size: 100,
      }).then(res => {
        const data = res.data.data;
        this.DIC.record = data.records.map(ele => {
          return {
            label: ele.name,
            value: ele.id
          }
        })
      });
    },
    handleRes (tip = true) {
      this.dataRes = ''
      this.dataOldRes = ''
      if (this.isSql) {
        this.$set(this.contain.activeObj, 'sql', crypto.encrypt(JSON.stringify({
          id: this.db,
          sql: this.sql
        })))
      }
      this.dataLoading = true
      this.contain.handleRefresh().then(({ news, old }) => {
        this.dataLoading = false
        this.dataRes = news
        this.dataOldRes = old
        if (tip) this.$message.success('请求数据成功')
      }).catch(() => {
        this.dataLoading = false
      }).finally(() => {
        this.dataLoading = false
      })
    },
    handleSetting () {
      this.resTabs = '0'
      this.dataRes = '';
      this.dataOldRes = ''
      this.handleRes(false)
      this.$refs.database.box = true;
    },
    initDataList () {
      if (this.contain.activeObj.sql && this.isSql) {
        let mode = JSON.parse(crypto.decrypt(this.contain.activeObj.sql));
        this.db = mode.id;
        this.sql = mode.sql;
      } else {
        this.db = '';
        this.sql = '';
      }
      this.DIC.public = this.contain.list.filter(ele => {
        return (ele.component || {}).prop == 'data'
      }).map(ele => {
        return {
          label: ele.name,
          value: ele.index
        }
      })
      this.DIC.filter = Object.keys(this.contain.config.filters).map(key => this.contain.config.filters[key])
      getDbList({
        current: 1,
        size: 100,
      }).then(res => {
        const data = res.data.data;
        this.DIC.sql = data.records.map(ele => {
          return {
            label: ele.name,
            value: ele.id
          }
        })
      });
      getCategoryList({
        categoryModule: 'record',
        current: 1,
        size: 99,
      }).then(res => {
        this.loading = false
        const data = res.data.data;
        this.DIC.recordType = [{ categoryKey: '全部', categoryValue: '' }].concat(data);
      })
    },
  }
}
</script>

<style lang="scss">
.databind {
  .menu__labe {
    width: 100%;
  }
  .menu__name {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
      padding: 0;
      line-height: 30px;
      height: 30px;
      flex: 1;
      margin-right: 5px;
    }
  }
}
</style>